import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Layout } from "../components/layout/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Slides } from "../components/hero/Slides";

const LaFilosofia = ({ data }) => {
  const { wpSezione } = data;
  const { title, content, laFilosofia } = wpSezione;
  const { gallery, herogallery, opacity, quotes } = laFilosofia;
  const quote = quotes[0];

  const getBoxes = elements => {
    const boxes = [];
    for (let i = 0; i <= elements.length; i++) {
      boxes.push(i);
      i += 3;
      boxes.push(i);
      i += 1;
      boxes.push(i);
      i += 3;
      boxes.push(i);
    }
    return boxes;
  };
  const smallBoxes = getBoxes(gallery);

  const DesktopView = () => (
    <div className="container pt-5 pb-5">
      <div className="row bg-white mt-5 mb-5">
        <div className="col-12 col-md-6 align-self-center">
          <div
            className="text-secondary"
            dangerouslySetInnerHTML={{ __html: quote.quote }}
          />
        </div>
        <div className="col-12 col-md-6 p-0">
          <GatsbyImage
            loading="eager"
            image={quote.image.localFile.childImageSharp.gatsbyImageData}
            alt="staff"
          />
        </div>
      </div>
    </div>
  );
  const MobileView = () => (
    <div className="container">
      <div className="row bg-white mt-5">
        <div className="col-12">
          <div
            className="text-secondary mb-5"
            dangerouslySetInnerHTML={{ __html: quote.quote }}
          />
        </div>
        <div className="col-12 p-0">
          <GatsbyImage
            loading="eager"
            image={quote.image.localFile.childImageSharp.gatsbyImageData}
            alt="chiara-cattini"
          />
        </div>
      </div>
    </div>
  );

  return (
    <main>
      <Helmet title={`${title} - Ristorante A Mangiare`} defer={false} />
      <Layout headerPosition="position-absolute" className="bg-white">
        <Slides
          arrows={true}
          title={title}
          content={content}
          opacity={opacity}
          images={herogallery}
        />
        <div className="d-none d-lg-block bg-tertiary">
          <DesktopView />
        </div>
        <div className="d-block d-lg-none bg-white">
          <MobileView />
        </div>
        <div className="bg-secondary">
          <div className="container pt-5 pb-5">
            <div className="row">
              {gallery.map((image, index) => {
                const masonryClass = smallBoxes.includes(index)
                  ? "col-md-7"
                  : "col-md-5";
                return (
                  <React.Fragment key={index}>
                    <div className={`col-12 ${masonryClass} align-self-center`}>
                      <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        alt={index.toString()}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>
    </main>
  );
};

export default LaFilosofia;

export const data = graphql`
  query {
    wpSezione(slug: { eq: "la-filosofia" }) {
      title
      content
      laFilosofia {
        herogallery {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        opacity
        quotes {
          quote
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
